.fab {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.14);

  transition: all 0.1s ease-in-out;

  text-align: center;

  position: fixed;
  right: 5vw;
  bottom: 5vh;
  width: 48px;
  height: 48px;

  background: linear-gradient(
    90deg,
    #2dc1d6 0.01%,
    #23b7d2 8.83%,
    #00a3cd 24.45%,
    #0086c2 44.88%,
    #0072bc 59.29%,
    #034ea2 100.05%
  );
  border-radius: 24px;
  cursor: pointer;

  color: white;
  font-size: 18px;
}

.fab:hover {
  transform: scale(1.05);
}

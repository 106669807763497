@import '~bootstrap/scss/bootstrap';

.App {
  font-family: sans-serif;
  text-align: center;
}

.video-player,
.audio-player {
  height: initial !important;
}

.audio-player video {
  height: 60px !important;
}

.ic-comments {
  width: 16px;
  height: 16px;
}

.kky-card {
  &__title {
  }
  &__author {
    font-size: 14px;
  }
  &-text {
    &__description {
      font-size: 12px;
    }
  }
}
